<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="$t(`cip.plat.xxljob.task.title.addHeadTitle`)"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script>

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {submit} from '@/api/xxlJob/jobTaskList'
import {
  pageList as getList
} from '@/api/xxlJob/jobActuatorList'


export default {
  name: "adInfoEdit",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {
        id:''
      },
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      if ([ 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        );
      }else if([ 'add'].includes(this.type)){
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t('cip.plat.xxljob.task.field.jobGroup'),
          prop: 'jobGroup',
          labelWidth: 180,
          span: 8,
          type: 'select',
          dicData:[],
          props: {
            label: "title",
            value: "id"
          },
          placeholder: this.$t(`cip.plat.xxljob.task.field.jobGroup`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.xxljob.task.field.jobGroup'),
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t('cip.plat.xxljob.task.field.jobDesc'),
          prop: 'jobDesc',
          labelWidth: 180,
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.xxljob.task.field.jobDesc`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.xxljob.task.field.jobDesc'),
              trigger: "blur"
            },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        },
        {
          label: this.$t('cip.plat.xxljob.task.field.executorRouteStrategy'),
          prop: 'executorRouteStrategy',
          labelWidth: 180,
          span: 8,
          maxlength: 50,
          type: 'select',
          dicUrl: `${baseUrl}EXECUTOR_ROUTE_STRATEGY`,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          placeholder: this.$t(`cip.plat.xxljob.task.field.executorRouteStrategy`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.xxljob.task.field.executorRouteStrategy'),
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t('cip.plat.xxljob.task.field.jobCron'),
          prop: 'jobCron',
          labelWidth: 180,
          span: 8,
          formslot:true,
          placeholder: this.$t(`cip.plat.xxljob.task.field.jobCron`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.xxljob.task.field.jobCron'),
              trigger: "change"
            },
          ],
        },
        {
          label: this.$t('cip.plat.xxljob.task.field.glueType'),
          prop: 'glueType',
          labelWidth: 180,
          span: 8,
          type: 'select',
          dicUrl: `${baseUrl}GLUE_TYPE`,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          placeholder: this.$t(`cip.plat.xxljob.task.field.glueType`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.xxljob.task.field.glueType'),
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t('cip.plat.xxljob.task.field.executorHandler'),
          prop: 'executorHandler',
          labelWidth: 180,
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.xxljob.task.field.executorHandler`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.xxljob.task.field.executorHandler'),
              trigger: "blur"
            },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        },
        {
          label: this.$t('cip.plat.xxljob.task.field.executorBlockStrategy'),
          prop: 'executorBlockStrategy',
          labelWidth: 180,
          span: 8,
          type: 'select',
          dicUrl: `${baseUrl}EXECUTOR_BLOCK_STRATEGY`,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          placeholder: this.$t(`cip.plat.xxljob.task.field.executorBlockStrategy`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.xxljob.task.field.executorBlockStrategy'),
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t('cip.plat.xxljob.task.field.childJobId'),
          prop: 'childJobId',
          labelWidth: 180,
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.xxljob.task.msg.childJobMessage`),
          readonly: !['add', 'edit'].includes(this.type),

        },
        {
          label: this.$t('cip.plat.xxljob.task.field.executorTimeout'),
          prop: 'executorTimeout',
          labelWidth: 180,
          span: 8,
          maxlength: 10,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.xxljob.task.field.executorTimeout`),
          readonly: !['add', 'edit'].includes(this.type),
        },
        {
          label: this.$t('cip.plat.xxljob.task.field.executorFailRetryCount'),
          prop: 'executorFailRetryCount',
          labelWidth: 180,
          span: 8,
          maxlength: 10,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.xxljob.task.field.executorFailRetryCount`),
          readonly: !['add', 'edit'].includes(this.type),
        },
        {
          label: this.$t('cip.plat.xxljob.task.field.author'),
          prop: 'author',
          labelWidth: 180,
          span: 8,
          maxlength: 30,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.xxljob.task.field.author`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.xxljob.task.field.author'),
              trigger: "blur"
            },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        },
        {
          label: this.$t('cip.plat.xxljob.task.field.alarmEmail'),
          prop: 'alarmEmail',
          labelWidth: 180,
          span: 8,
          maxlength: 20,
          placeholder: this.$t(`cip.plat.xxljob.task.field.alarmEmail`),
          readonly: !['add', 'edit'].includes(this.type),
        },
        {
          label: this.$t('cip.plat.xxljob.task.title.actator'),
          prop: 'cateId',
          type: 'tree',
          labelWidth: 180,
          span: 8,
          dicUrl: `/api/sinoma-xxljob-admin/jobInfoCate/tree`,
          props: {
            label: "name",
            value: "id"
          },
          maxlength: 20,
          row:true,
          placeholder: this.$t(`cip.plat.xxljob.task.field.alarmEmail`),
        },
        {
          label: this.$t('cip.plat.xxljob.task.field.executorParam'),
          labelWidth: 180,
          maxlength: 200,
          showWordLimit: true,
          prop: 'remark',
          type: 'textarea',
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.xxljob.task.field.executorParam`),
        },
      ]
    },
  },
  created() {
    let {id, type, node} = this.$route.query;
    this.type = type;
    this.dataForm.cateId = node.id
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
    }
    this.getActatorData()
  },
  mounted() {

  },
  methods: {
    getActatorData(){
      let searchForm = {
        appname:"",
        title:""
      }
      let start = 1
      let length = 20
      getList(start,length, Object.assign(searchForm)).then(res =>{
        const column = this.findObject(this.formColumn, "jobGroup");
        column.dicData = res.data.data
      })
    },
    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$loading();
          submit({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              this.$message.success(this.$t(`cip.cmn.msg.success.operateSuccess`))
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$loading().close();
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style scoped>

</style>
